export class UserListItemDto {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  keywords: string[];

  rights: string[];

  link: string;

  tel: string;

  activated: boolean;

  disabled: boolean;

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    keywords: string[],
    rights: string[],
    tel: string,
    activated: boolean,
    disabled: boolean
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.keywords = keywords;
    this.rights = rights;
    this.tel = tel;
    this.activated = activated;
    this.disabled = disabled;
  }
}
