export const defaultRoutes = {
  super_admin: ['workflows'],

  admin: ['administration'],
  admin_mp: ['administration'],
  admin_div: ['administration'],

  gest_circuit: ['workflows'],
  gest_circuit_mp: ['workflows'],
  gest_circuit_div: ['workflows'],

  gest_circuit_junior: ['workflows'],
  gest_circuit_junior_mp: ['workflows'],
  gest_circuit_junior_div: ['workflows'],

  gest_modele: ['templates'],
  gest_modele_mp: ['templates'],
  gest_modele_div: ['templates'],

  resp_metier: ['workflows'],
  resp_metier_mp: ['workflows'],
  resp_metier_div: ['workflows'],

  resp_suivi: ['workflows'],
  resp_suivi_mp: ['workflows'],
  resp_suivi_div: ['workflows'],

  virtual_user: ['contacts'],
  contact: ['contacts'],

  destinataire: ['workflows'],
  destinataire_mp: ['workflows'],
  destinataire_div: ['workflows'],
};
