import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { CredentialsService } from '../../@services/authentification/credentials.service';
import { GuardsUtilsHelper } from '@core/helpers/guards-utils.helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private router = inject(Router);

  private userService = inject(CredentialsService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const rootPath = GuardsUtilsHelper.pathBuilder(route);
    const sessionId = route.queryParams.token;

    if (sessionId) {
      return this.userService.attemptJWTAuth(sessionId).pipe(
        switchMap(() => {
          return this.userService.isAuthenticated;
        }),
        tap((isAuthenticated) => {
          if (!isAuthenticated) {
            this.router.navigate([rootPath, 'login'], {
              queryParams: { redirect: state.url },
              replaceUrl: true,
            });
          }
        }),
        take(1)
      );
    } else {
      return this.userService.isAuthenticated.pipe(
        tap((isAuthenticated) => {
          if (!isAuthenticated) {
            this.router.navigate([rootPath, 'login'], {
              queryParams: { redirect: state.url },
              replaceUrl: true,
            });
          }
        }),
        take(1)
      );
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
