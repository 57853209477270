import { Directive, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { CredentialsService } from '@app/@services/authentification/credentials.service';

@Directive({
  selector: '[appNotLoggedIn]',
  standalone: true,
})
export class NotLoggedInDirective implements OnInit {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);

  private viewContainer = inject(ViewContainerRef);

  private userService = inject(CredentialsService);

  ngOnInit() {
    return this.userService.isAuthenticated.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
