import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CredentialsService } from '../../@services/authentification/credentials.service';
import { SessionPopupHelperService } from '@shared/session-expired/business/helpers/session-popup-helper.service';
const SECOND_ACTION_LINK = 'second_action_link';

@Injectable({
  providedIn: 'root',
})
export class AuthNotAuthorizedInterceptor implements HttpInterceptor {
  private credentialsService = inject(CredentialsService);

  private sessionPopupHelperService = inject(SessionPopupHelperService);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const excludeUrls = ['internal/authenticate']; //url

        if (excludeUrls.some((e) => req.url.includes(e))) {
          return next.handle(req);
        }

        const { status } = error;
        const token = window.sessionStorage.jwtToken;

        if (
          status === 401 ||
          status === 403 ||
          (status === 404 && this.sessionPopupHelperService.tokenExpired(token))
        ) {
          if (error.error.code !== 'ACCESS_DENIED') {
            this.credentialsService.purgeAuth();
            window.location.reload();
          } else {
            if (window.sessionStorage.getItem(SECOND_ACTION_LINK) !== null) {
              this.credentialsService.purgeAuth();
            } else {
              this.sessionPopupHelperService.showSessionExpiredModal();
              this.credentialsService.purgeAuth();
            }
          }
        }

        return throwError(error);
      })
    );
  }
}
