import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserContextApiService } from '@app/@services/user-context/user-context.api.service';
import { EntityRefService } from '@app/@services/entity-ref.service';
import { UserContext, UserContextService } from '@app/@services/authentification/user-context.service';
import { CredentialsService } from '@app/@services/authentification/credentials.service';

@Injectable({ providedIn: 'root' })
export class NotfoundResolver {
  private credentialsService = inject(CredentialsService);

  private userContextApiService = inject(UserContextApiService);

  private router = inject(Router);

  private entityRefService = inject(EntityRefService);

  private userContexteService = inject(UserContextService);

  isAuthenticated = false;

  userContext: UserContext = {};

  constructor() {
    this.credentialsService.isAuthenticated.subscribe((isAuth) => (this.isAuthenticated = isAuth));
  }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (!!this.isAuthenticated) {
      this.userContext.entityName = this.userContexteService.getUserContext().entityName;
      this.userContext.entityRef = this.userContexteService.getUserContext().entityRef;
      this.userContext.currentMarketplace = this.userContexteService.getUserContext().currentMarketplace;

      this.entityRefService.entityRef = this.userContext.entityRef;
      this.entityRefService.currentMarketplaceName = this.userContext.currentMarketplace;
      this.entityRefService.entityName = this.userContext.entityName;

      return this.userContextApiService.getAccount();
    }
  }
}
