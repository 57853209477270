import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
})
export class MenuComponent {
  readonly visible$ = new Subject<boolean>();

  @ViewChild(TemplateRef, { static: true }) menuTemplate: TemplateRef<any>;

  constructor() {}
}

//NOTE: example use :
// <div >
//   <button [appMenuTrigger]="menuTmpl">Open</button>
// </div>

// <app-menu #menuTmpl>
//   <div>
//     something bla bla blo blo
//   </div>
// </app-menu>
