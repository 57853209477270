import { Component, OnInit, inject } from '@angular/core';
import { Lang } from '@app/@shared';
import { I18nService } from '@app/@i18n/i18n.service';
import { FooterAccessConfig } from '@shared/footer/config/footer-access.config';
import { DOCUMENT, UpperCasePipe } from '@angular/common';
import { NavigationService } from '@app/@services/navigation/navigation.service';
import { ChangeLangueService } from '@app/@services/changeLang.service';
import supportedLangs from '@app/@i18n/translation/lang.json';
import { UserContextApiService } from '@app/@services/user-context/user-context.api.service';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import packageInfo from '../../../../package.json';
import { TranslateModule } from '@ngx-translate/core';
import { MenuTriggerDirective } from '../lib/menu/menu-trigger.directive';
import { MenuComponent } from '../lib/menu/menu.component';
import { NotLoggedInDirective } from '../directives/logged-in/not-logged-in.directive';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NotLoggedInDirective, MenuComponent, MenuTriggerDirective, RouterLink, UpperCasePipe, TranslateModule],
})
export class FooterComponent implements OnInit {
  private navigationService = inject(NavigationService);

  private i18nService = inject(I18nService);

  private changeLangueService = inject(ChangeLangueService);

  private userContextApiService = inject(UserContextApiService);

  private document = inject<Document>(DOCUMENT);

  private router = inject(Router);

  supportedLangs: Array<Lang> = [];

  selectedLang: Lang;

  accessToFooter = true;

  appVersion: string;

  rootPath: string;

  entityAddons: any;

  constructor() {
    this.supportedLangs = this.getSupportedLangs();
    this.selectedLang = this.supportedLangs[0];
    this.setHtmlLang();
    this.appVersion = packageInfo.version;
    this.rootPath = this.navigationService.buildPath();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.accessToFooter = FooterAccessConfig.hideOnRoutes.reduce((hasAccess, hiddenRoute) => {
          return hasAccess && !event.url.includes(hiddenRoute);
        }, true);
      }
    });
  }

  drawPath(subPath: string): string {
    return '/' + this.rootPath + '/overall-infos/' + subPath;
  }

  onLangChange(lang: Lang) {
    this.selectedLang = lang;
    this.i18nService.language = this.selectedLang.langId;
    this.setHtmlLang();
    this.changeLangueService.changeLange = true;
  }

  getSupportedLangs() {
    const entity = JSON.parse(window.sessionStorage.getItem('userContext'));
    const avaibleLangs = [];

    this.userContextApiService.getEntity(entity?.entityName).subscribe((data) => {
      this.entityAddons = data.addOns;
    });
    /****** temporary!! : delete the filter to get all languages ******/
    this.entityAddons?.availableLocales.forEach((langue) => {
      if (supportedLangs.filter((lang) => lang.langId === langue)[0]) {
        avaibleLangs.push(supportedLangs.filter((lang) => lang.langId === langue)[0]);
      }
    });

    return avaibleLangs;
  }

  private setHtmlLang(): void {
    this.document.documentElement.lang = this.selectedLang.langId;
  }
}
