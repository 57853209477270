import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityRefService } from '../../@services/entity-ref.service';
import { Logger } from '../logger.service';
const log = new Logger('EntityRefInterceptor');
const headersConfig = {};

@Injectable()
export class EntityRefInterceptor implements HttpInterceptor {
  private entityRefService = inject(EntityRefService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req.url.includes('internal/entities/front/')
      ? (headersConfig['x-pes-entity-ref'] = 'SELV2')
      : (headersConfig['x-pes-entity-ref'] = this.entityRefService.entityRef);
    const request = req.clone({ setHeaders: headersConfig });

    return next.handle(request);
  }
}
