import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { divisionRights, entityRights, Groups, marketplaceRights } from '../roles/groups.enum';
import { AppPathType, UserRoleType } from '@shared/models/context/app-context';
import { UserUtilsHelper } from '@core/helpers/user-utils.helper';
import { UserContextApiService } from '@app/@services/user-context/user-context.api.service';
import { EntityRefService } from '@app/@services/entity-ref.service';
import { AccountContextService } from '@app/@services/account/account-context.service';
import { UserContextService } from '@app/@services/authentification/user-context.service';
import { giMarketplacesNames, giUserMarketplaces, giUserRoles, initPathRoleType } from '@app/app.global.immutables';

const AllowedUsers = [
  Groups.super_admin,
  Groups.admin,
  Groups.gest_circuit,
  Groups.gest_circuit_junior,
  Groups.gest_modele,
  Groups.resp_metier,
  Groups.resp_suivi,
  Groups.destinataire,
];

@Injectable({
  providedIn: 'root',
})
export class MarketplaceGuard {
  private userContextApiService = inject(UserContextApiService);

  private entityRefService = inject(EntityRefService);

  private router = inject(Router);

  private userContextService = inject(UserContextService);

  private accountContextService = inject(AccountContextService);

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const entity = route.params.entity;
    const mp = route.params.mp;

    return this.getFromStoreOrAPI(entity, mp).pipe(
      catchError((err) => {
        const { status } = err;

        if (status === 404) {
          this.router.navigate(['/help'], {
            queryParams: { message: "l'entité introuvable" },
          });
        }

        return of(false);
      }),
    );
  }

  getFromStoreOrAPI(entityName: string, marketplaceName: string): Observable<any> {
    return this.userContextApiService.combineEntityWithMP(entityName, marketplaceName).pipe(
      tap((res: any) => {
        this.handleEntityRef(res, marketplaceName);
        // for entity ref interceptor !
        this.saveUserContext(res.reference, entityName, marketplaceName);
      }),
      switchMap(() => this.handleAuthorization(entityName, marketplaceName)),
    );
  }

  handleEntityRef(res: any, marketplaceName: string) {
    this.entityRefService.entityRef = res.reference;
    this.entityRefService.entityName = res.name;
    this.entityRefService.entityFrontPath = res.frontPath;
    this.entityRefService.currentMarketplaceName = marketplaceName;
  }

  saveUserContext(entityRef: any, entityName: string, currentMarketplace: string): void {
    this.userContextService.saveUserContext({
      entityRef,
      entityName,
      currentMarketplace,
    });
  }

  handleAuthorization(entityName: string, marketplaceName: string): Observable<any> {
    const selectAuth$ = this.userContextApiService.getAccount();
    const setMarketplaceTree$ = this.userContextApiService.getMarketplaceTree();

    return forkJoin([selectAuth$, setMarketplaceTree$]).pipe(
      map(([_auth, _marketplaceTree]: [any, any]) => {
        return {
          roles: giUserRoles,
          mpCurrentAccount: giUserMarketplaces || [],
          availableMarketplaces: giMarketplacesNames,
        };
      }),
      filter((auth: any) => {
        return auth.roles.length > 0 && (auth.mpCurrentAccount?.length > 0 || auth.availableMarketplaces?.length > 0);
      }),
      map((auth: any) => this.manageRedirection(auth, entityName, marketplaceName)),
    );
  }

  manageRedirection(
    { roles: roles, availableMarketplaces: _availableMarketplaces, mpCurrentAccount: mpCurrentAccount },
    entityName: string,
    marketplaceName: string,
  ) {
    this.publishContext(roles);

    if (roles.some((r) => AllowedUsers.includes(r))) {
      return true;
    }

    if (mpCurrentAccount.includes(marketplaceName)) {
      return true;
    } else {
      // here should be a notif !
      const firstMpCurrent = mpCurrentAccount[0];

      if (
        UserUtilsHelper.userProfiles(roles).length === 1 &&
        UserUtilsHelper.userProfiles(roles)[0] === 'gest_modele'
      ) {
        this.router.navigateByUrl(`${entityName}/${firstMpCurrent}/templates`);
      } else {
        this.router.navigateByUrl(`${entityName}/${firstMpCurrent}/workflows`);
      }

      return false;
    }
  }

  publishContext(roles: string[]) {
    const appContext = {
      appPathType: AppPathType.MARKETPLACE_PATH,
      userRoleType: undefined,
    };

    if (divisionRights.some((divRight) => roles.includes(divRight))) {
      appContext.userRoleType = UserRoleType.USER_DIVISION;
      this.accountContextService.isUserDivision = true;
      this.accountContextService.isUserMarketplace = false;
      this.accountContextService.isUserEntity = false;
    } else if (marketplaceRights.some((mpRight) => roles.includes(mpRight))) {
      appContext.userRoleType = UserRoleType.USER_MARKETPLACE;
      this.accountContextService.isUserDivision = false;
      this.accountContextService.isUserMarketplace = true;
      this.accountContextService.isUserEntity = false;
    } else if (entityRights.some((entityRight) => roles.includes(entityRight))) {
      appContext.userRoleType = UserRoleType.USER_ENTITY;
      this.accountContextService.isUserDivision = false;
      this.accountContextService.isUserMarketplace = false;
      this.accountContextService.isUserEntity = true;
    }

    initPathRoleType(appContext.appPathType, appContext.userRoleType);
  }
}
