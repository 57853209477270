import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChangeLangueService {
  changeLange = false;

  set Langue(langChanged: boolean) {
    this.changeLange = langChanged;
  }

  get Langue() {
    return this.changeLange;
  }
}
