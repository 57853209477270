@if (loader.getLoading()) {
  <div class="spinner-wrapper">
    <div class="spinner-position">
      <div class="spinner">
        <span class="sr-only">{{ 'DOWLOAD_RECIPIENT_FILE' | translate }}</span>
      </div>
      @if (loader.getMessage()) {
        <p>{{ loader.getMessage() }}</p>
      }
    </div>
  </div>
}
