import { ActivatedRouteSnapshot } from '@angular/router';

export class GuardsUtilsHelper {
  static pathBuilder(snapshot: ActivatedRouteSnapshot) {
    const entity = snapshot.parent.paramMap.get('entity');
    const marketPlace = snapshot.parent.paramMap.get('mp');

    return marketPlace !== undefined && marketPlace !== null ? `${entity}/${marketPlace}` : `${entity}`;
  }
}
