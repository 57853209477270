import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EntityRefService } from '@app/@services/entity-ref.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router = inject(Router);

  private entityRefService = inject(EntityRefService);

  buildPath() {
    const entityFrontPath = this.entityRefService.entityFrontPath;
    const marketPlace = this.entityRefService.currentMarketplaceName;

    return marketPlace !== undefined ? `${entityFrontPath}/${marketPlace}` : `${entityFrontPath}`;
  }

  navigate(url: string[], extra?: any) {
    const extraData = { ...extra, replaceUrl: true };

    return this.router.navigate([this.buildPath(), ...url], extraData);
  }

  reloadCurrentRoute(url: string) {
    return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
}
