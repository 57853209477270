import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { CredentialsService } from '@app/@services/authentification/credentials.service';

@Injectable({ providedIn: 'root' })
export class RedirectExternalProvider {
  private credentialsService = inject(CredentialsService);

  isAuthenticated = false;

  externalRedirectionMap = {
    'localhost:4200': 'http://localhost:4300/#/clients',
    'recint.signerenligne.com': 'https://pes.docapost-bpo.com/recint/selv2/#/clients',
    'recext.signerenligne.com': 'https://pes.docapost-bpo.com/rct/selv2/#/clients',
    'preprod.signerenligne.com': 'https://pes.docapost-bpo.com/pprd/selv2/#/clients',
    'www.signerenligne.com': 'https://pes.docapost-bpo.com/selv2/#/clients',
  };

  constructor() {
    this.credentialsService.isAuthenticated.subscribe((isAuth) => (this.isAuthenticated = isAuth));
  }

  resolve(): Observable<any> | Promise<any> | any {
    const urlParts = window.location.href.split('clients');

    Object.keys(this.externalRedirectionMap).forEach((key) => {
      if (urlParts.length > 1 && urlParts[0] !== undefined && urlParts[0].includes(key)) {
        window.location.href = this.externalRedirectionMap[key] + urlParts[1];
      }
    });
  }
}
