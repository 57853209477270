import { Injectable, inject } from '@angular/core';
import { I18nService } from '@app/@i18n/i18n.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeNl from '@angular/common/locales/nl';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeItExtra from '@angular/common/locales/extra/it';
import localeDeExtra from '@angular/common/locales/extra/de';
import localePlExtra from '@angular/common/locales/extra/pl';
import localeNlExtra from '@angular/common/locales/extra/nl';

/**
 * The service that manages the LOCALE_ID token for the application.
 */
@Injectable({ providedIn: 'root' })
export class LocaleIdService extends String {
  private i18nService = inject(I18nService);

  /**
   * @private
   */
  constructor() {
    super('');
    registerLocaleData(localeFr, 'fr', localeFrExtra);
    registerLocaleData(localeEs, 'es', localeEsExtra);
    registerLocaleData(localeIt, 'it', localeItExtra);
    registerLocaleData(localeDe, 'de', localeDeExtra);
    registerLocaleData(localePl, 'pl', localePlExtra);
    registerLocaleData(localeNl, 'nl', localeNlExtra);
  }

  /**
   * @inheritDoc
   */
  public toString(): string {
    return this.i18nService.language.split('messages.').length > 1
      ? this.i18nService.language.split('messages.')[1]
      : this.i18nService.language;
  }
}
