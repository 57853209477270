import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { CredentialsService } from '@app/@services/authentification/credentials.service';
import { GuardsUtilsHelper } from '@core/helpers/guards-utils.helper';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
  private userService = inject(CredentialsService);

  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot) {
    const rootPath = GuardsUtilsHelper.pathBuilder(route);

    return this.userService.isAuthenticated.pipe(
      tap((isAuthenticated) => {
        if (!!isAuthenticated) {
          this.router.navigate([rootPath]);
        }
      }),
      map((isAuthenticated) => !isAuthenticated),
      take(1)
    );
  }
}
