import { Injectable, inject } from '@angular/core';
import { SessionExpiredPopupComponent } from '@shared/session-expired/component/session-expired-popup/session-expired-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class SessionPopupHelperService {
  private modalService = inject(NgbModal);

  showSessionExpiredModal() {
    const modalRef = this.modalService.open(SessionExpiredPopupComponent, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;

    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
