import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class CredentialsService {
  private tokenService = inject(TokenService);

  private http = inject(HttpClient);

  path = '/internal/authenticate';

  pathVirtualUser = '/internal/authenticate/jwt/';

  isAuthenticatedSubject = new ReplaySubject<boolean>(1);

  isAuthenticated = this.isAuthenticatedSubject.asObservable();

  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.tokenService.getToken()) {
      this.isAuthenticatedSubject.next(true);
    } else {
      // Remove any potential remnants of previous auth states
      this.isAuthenticatedSubject.next(false);
    }
  }

  setAuth(token: string) {
    // Save JWT sent from server in localstorage
    this.tokenService.saveToken(token);

    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // revoke token
    this.logout();
    // Remove JWT from localstorage
    this.tokenService.destroyToken();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(account: any): Observable<any> {
    return this.http.post(this.path, account).pipe(
      map((data: any) => {
        this.setAuth(data.id_token);
        return data;
      })
    );
  }

  attemptJWTAuth(sessionId: string): Observable<any> {
    return this.http.get(this.pathVirtualUser + sessionId).pipe(
      map((data: any) => {
        this.setAuth(data.id_token);
        return data;
      })
    );
  }

  // ..../jwt/seldm-......
  attemptAuthForVirtualUser(actionSessionToken: any): Observable<any> {
    return this.http.get(this.pathVirtualUser + actionSessionToken).pipe(
      map((data: any) => {
        this.setAuth(data.id_token);
        return data;
      })
    );
  }

  getAccount(): Observable<any> {
    const path = '/v3/user/current';

    return this.http.get(path);
  }

  /**
   * https://contralia.atlassian.net/browse/SV-18215
   * @private
   */
  private logout(): void {
    this.http.get(this.path + '/logout').subscribe();
  }
}
