import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import ar from './translation/messages.ar.json';
import de from './translation/messages.de.json';
import en from './translation/messages.en.json';
import enUS from './translation/messages.en-US.json';
import es from './translation/messages.es.json';
import fr from './translation/messages.fr.json';
import it from './translation/messages.it.json';
import nl from './translation/messages.nl.json';
import pt from './translation/messages.pt.json';
import sv from './translation/messages.sv.json';
import ro from './translation/messages.ro.json';
import cs from './translation/messages.cs.json';
import lu from './translation/messages.lu.json';
import pl from './translation/messages.pl.json';
import supportedLangs from './translation/lang.json';
import { giEntity } from '@app/app.global.immutables';

@Injectable({ providedIn: 'root' })
export class I18nService {
  private translateService = inject(TranslateService);

  entityAddons: any;

  defaultLanguage!: string;

  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor() {
    // Embed languages to avoid extra HTTP requests
    this.translateService.setTranslation('ar', ar);
    this.translateService.setTranslation('de', de);
    this.translateService.setTranslation('en', en);
    this.translateService.setTranslation('en-US', enUS);
    this.translateService.setTranslation('es', es);
    this.translateService.setTranslation('fr', fr);
    this.translateService.setTranslation('it', it);
    this.translateService.setTranslation('nl', nl);
    this.translateService.setTranslation('sv', sv);
    this.translateService.setTranslation('ro', ro);
    this.translateService.setTranslation('cs', cs);
    this.translateService.setTranslation('de-LU', lu);
    this.translateService.setTranslation('pl', pl);
    this.translateService.setTranslation('pt', pt);
  }

  getSupportedLangs() {
    const avaibleLangs = [];

    /****** temporary!! : delete the filter to get all languages ******/
    giEntity.addOns?.availableLocales.forEach((langue) => {
      if (supportedLangs.filter((lang) => lang.langId === langue)[0]) {
        avaibleLangs.push(supportedLangs.filter((lang) => lang.langId === langue)[0]);
      }
    });

    return avaibleLangs;
  }

  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = defaultLanguage;
  }

  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  set language(language: string) {
    language = language || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages?.includes(language);

    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages?.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    this.translateService.use(language);
  }

  get language(): string {
    return this.translateService.currentLang;
  }
}
