export enum Rights {
  ALL = 'ALL', //0

  //
  // CIRCUITS
  //
  CIRCUIT_VIEW = 'CIRCUIT_VIEW',
  CIRCUIT_CREATE = 'CIRCUIT_CREATE',
  CIRCUIT_DELETE = 'CIRCUIT_DELETE',
  CIRCUIT_CANCEL = 'CIRCUIT_CANCEL',
  CIRCUIT_VIEW_ALL = 'CIRCUIT_VIEW_ALL',
  CIRCUIT_RESEND = 'CIRCUIT_RESEND',
  DOWNLOAD_EVIDENCE_FILE = 'DOWNLOAD_EVIDENCE_FILE',
  DOWNLOAD_EVIDENCE_FOLDER = 'DOWNLOAD_EVIDENCE_FOLDER',
  DOWNLOAD_SUPPORTING_DOCUMENTS = 'DOWNLOAD_SUPPORTING_DOCUMENTS',
  CIRCUIT_DUPLICATE = 'CIRCUIT_DUPLICATE',
  CIRCUIT_MASSE_MODEL = 'CIRCUIT_MASSE_MODEL',
  CIRCUIT_UPDATE = 'CIRCUIT_UPDATE',
  EXPORT = 'EXPORT',

  //
  // MODELS
  //
  MODEL_VIEW = 'MODEL_VIEW',
  MODEL_CREATE = 'MODEL_CREATE',
  MODEL_DELETE = 'MODEL_DELETE',
  MODEL_USE = 'MODEL_USE',
  MODEL_DUPLICATE = 'MODEL_DUPLICATE',
  MODEL_BLOCK = 'MODEL_BLOCK',

  //
  // ADMIN TECHNIQUE : USER / CONTACT / GROUP
  //

  USER_VIEW = 'USER_VIEW',
  USER_WRITE = 'USER_WRITE',
  USER_DELETE = 'USER_DELETE',
  CONTACT_VIEW = 'CONTACT_VIEW',
  CONTACT_WRITE = 'CONTACT_WRITE',
  CONTACT_DELETE = 'CONTACT_DELETE',
  DIVISION_WRITE = 'GROUP_WRITE',
  DIVISION_READ = 'GROUP_READ',

  //
  // ADMIN FCT : USER / CONTACT
  //
  REPORT_VIEW = 'REPORT_VIEW',

  //
  // CONSO
  //
  CONSO_VIEW = 'CONSO_VIEW',
  CONSO_PARAM = 'CONSO_PARAM',

  //
  // USER ACCOUNT
  //
  PREFERENCE_VIEW = 'PREFERENCE_VIEW',

  //
  // ANONYME / NOT CONNECTED
  //
  ANONYMOS = 'ANONYMOS',
  NOT_BACK = 'NOT_BACK',
  HELP_VIEW = 'HELP_VIEW',
  SIGNATURES = 'SIGNATURES',
  ETAPE_PARAMETRE = 'ETAPE_PARAMETRE',

  //REFACTORING
  HOME = 'HOME',
  MANAGEMENT = 'MANAGEMENT',
  CIRCUIT_TOGGLE = 'CIRCUIT_TOGGLE',

  //CLIENT
  CLIENT = 'CLIENT',
  ENTITY_CREATE = 'ENTITY_CREATE',
}
