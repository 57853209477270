import { Logger } from '@app/@core/logger.service';
import { UserRoleType } from '@shared/models/context/app-context';
import { EntityAddons } from '@shared/models/entity/entity-addons';

const log = new Logger('app.global.immutables');

const gi = {};

log.debug(gi);

export interface IEntity {
  reference: string;
  addOns: EntityAddons;
  name: string;
  oldFrontPath?: string;
  frontPath: string;
  frontBaseUrl: string;
}

export let giEntity: IEntity;
export let giGroups = [];
export let giUserMpsAndDivs = [];
export let giAccount;
export let giCurrentGroupName;
export let giAppPathType;
export let giUserRoleType;
export let giUserRoles;
export let giUserMarketplaces;
export let giIsVirtualUser = false;
export let giCurrentGroup;
export let giAreMarketplacesLoaded = false;
export let giDivisionTree = [];
export let giMarketplaceTree = [];
export let giMarketplacesNames = [];
export let giIsIdClaimActive = false;

export function initAreMarketplacesLoaded(areMarketplacesLoaded) {
  giAreMarketplacesLoaded = areMarketplacesLoaded;
  gi['giAreMarketplacesLoaded'] = giAreMarketplacesLoaded;
}

export function initCurrentGroupName(currentGroupeName) {
  giCurrentGroupName = currentGroupeName;
  gi['giCurrentGroupName'] = giCurrentGroupName;

  initCurrentGroup();
}

export function initEntity(entity) {
  giEntity = normalizeEntity(entity);
  gi['giEntity'] = giEntity;
  extractInfo();
}

function normalizeEntity(entity) {
  entity.addOns.preAuthentificationTypes = !entity.addOns.preAuthentificationTypes?.length
    ? ['sms']
    : entity.addOns.preAuthentificationTypes;
  return entity;
}

function extractInfo() {
  giIsIdClaimActive = giEntity.addOns.advancedSignLevelWithTemporaryConfig?.some(
    (config) => config.name === 'simple' && config.temporary === false
  );
  gi['giIsIdClaimActive'] = giIsIdClaimActive;
}

export function initGroups(groups: any) {
  giGroups = groups;
  gi['giGroups'] = giGroups;

  giDivisionTree = groups.filter((group) => group.type === 'division');
  giMarketplaceTree = groups.filter((group) => group.type === 'marketplace');
  gi['giDivisionTree'] = giDivisionTree;
  gi['giMarketplaceTree'] = giMarketplaceTree;

  giMarketplacesNames = giMarketplaceTree.map((marketplace) => marketplace.name);
  gi['giMarketplacesNames'] = giMarketplacesNames;

  initUserGroups();
}

export function initAccount(account: any) {
  giAccount = account;
  gi['giAccount'] = giAccount;

  initUserGroups();
  initCurrentGroup();
  initRoles();
  initMarketplaces();
}

function initMarketplaces() {
  giUserMarketplaces =
    giAccount.features?.groups?.reduce(
      (res, { type, name, parentId, entityRef }) =>
        Array.from(
          new Set([
            ...res,
            ...(type === 'marketplace'
              ? [name]
              : type === 'division' && parentId !== undefined
              ? [parentId && parentId.split(`@${entityRef}@`)[1]]
              : []),
          ])
        ),
      []
    ) || [];
  gi['giUserMarketplaces'] = giUserMarketplaces;
}

function initRoles() {
  giUserRoles = giAccount?.features?.groups
    ? giAccount.groups.filter((r) => r.type === 'profile' && r.name !== 'contact').map((e) => e.name)
    : [];
  gi['giUserRoles'] = giUserRoles;
}

export function initPathRoleType(appPathType, userRoleType) {
  gi['giAppPathType'] = appPathType;
  gi['giUserRoleType'] = userRoleType;

  giAppPathType = appPathType;
  giUserRoleType = userRoleType;

  gi['giAppPathType'] = giAppPathType;
  gi['giUserRoleType'] = giUserRoleType;

  initUserGroups();
}

export function initVirtualUser(isVirtualUser: any) {
  giIsVirtualUser = isVirtualUser;
  gi['giIsVirtualUser'] = giIsVirtualUser;
}

function initUserGroups() {
  if (!giUserRoleType) return;

  if (giUserRoleType === UserRoleType.USER_ENTITY) {
    giUserMpsAndDivs = giGroups;
  } else if (giAccount && giGroups) {
    giUserMpsAndDivs = giAccount.groups.filter((accountGroup) =>
      ['marketplace', 'division'].includes(accountGroup.type)
    );
  }

  gi['giUserMpsAndDivs'] = giUserMpsAndDivs ?? [];
}

function initCurrentGroup() {
  if (giCurrentGroupName && giGroups) {
    giCurrentGroup = giGroups.find((group) => group.name === giCurrentGroupName);

    if (!giCurrentGroup) {
      for (const group of giGroups) {
        giCurrentGroup = group.children.find((childGroup) => childGroup.name === giCurrentGroupName);
        if (giCurrentGroup) break;
      }
    }
  }

  gi['giCurrentGroup'] = giCurrentGroup;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deepFreeze(obj) {
  // fetch property keys
  const propKeys = Object.getOwnPropertyNames(obj);

  // recursively freeze all properties
  propKeys.forEach((key) => {
    const propValue = obj[key];

    if (propValue && typeof propValue === 'object') deepFreeze(propValue);
  });

  return Object.freeze(obj);
}
