import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EntityRefService {
  private _entityRef: string;

  private _entityName: string;

  private _entityFrontPath: string;

  private _currentMarketplaceName: string;

  private _entityAddOns: any;

  get entityRef(): string {
    return this._entityRef;
  }

  set entityRef(val: string) {
    this._entityRef = val;
  }

  get entityName(): string {
    return this._entityName;
  }

  set entityName(val: string) {
    this._entityName = val;
  }

  get currentMarketplaceName(): string {
    return this._currentMarketplaceName;
  }

  set currentMarketplaceName(value: string) {
    this._currentMarketplaceName = value;
  }

  get entityFrontPath(): string {
    return this._entityFrontPath;
  }

  set entityFrontPath(value: string) {
    this._entityFrontPath = value;
  }

  get entityAddOns(): any {
    return this._entityAddOns;
  }

  set entityAddOns(value: any) {
    this._entityAddOns = value;
  }
}
