import { Injectable, inject } from '@angular/core';
import { UserAccount } from '@shared';
import * as momentTZ from 'moment-timezone';
import { FullUserObject } from '@shared/models/user/full-user-object';
import { UserListItemDto } from '@modules/administration/components/user-list/models/user-list-item.dto';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserMapperService {
  private translateService = inject(TranslateService);

  roleList = [
    'admin',
    'resp_metier',
    'resp_suivi',
    'gest_modele',
    'gest_circuit',
    'gest_circuit_junior',
    'destinataire',
    'virtual_user',
    'anonymous_user',
  ];

  public backendToFrontUser(backenUser: any) {
    const cloneBackenUser = Object.assign({}, backenUser);
    const frontAccount = backenUser;

    frontAccount.activated = cloneBackenUser.activated;
    frontAccount.disabled = cloneBackenUser.disabled;
    frontAccount.id = cloneBackenUser.id;
    frontAccount.login = cloneBackenUser.login;
    frontAccount.email = cloneBackenUser.email;
    frontAccount.firstname = cloneBackenUser.firstName;
    frontAccount.lastname = cloneBackenUser.lastName;

    frontAccount.phone = cloneBackenUser.phone;

    if (!cloneBackenUser.groups) {
      frontAccount.groups = [];
    }

    frontAccount.groups = cloneBackenUser.groups;

    if (!cloneBackenUser.addOns) {
      frontAccount.addOns = {};
    }

    frontAccount.addOns = {};
    frontAccount.addOns.workflowDefaultLang =
      cloneBackenUser.addOns && cloneBackenUser.addOns.workflowDefaultLang
        ? cloneBackenUser.addOns.workflowDefaultLang.toLowerCase()
        : '';
    frontAccount.addOns.nickName =
      cloneBackenUser.addOns && cloneBackenUser.addOns.nickName ? cloneBackenUser.addOns.nickName : '';
    frontAccount.addOns.timeZone =
      cloneBackenUser.addOns && cloneBackenUser.addOns.timeZone ? cloneBackenUser.addOns.timeZone : momentTZ.tz.guess();

    frontAccount.addOns.visualSign = cloneBackenUser.addOns ? cloneBackenUser.addOns.visualSign : [];

    (frontAccount.addOns.emailSettingsRecipient = cloneBackenUser.addOns
      ? cloneBackenUser.addOns.emailSettingsRecipient
      : []),
      (frontAccount.addOns.emailSettingsStarter = cloneBackenUser.addOns
        ? cloneBackenUser.addOns.emailSettingsStarter
        : []);

    frontAccount.addOns.tags = cloneBackenUser.addOns ? cloneBackenUser.addOns.tags : [];

    return frontAccount;
  }

  frontToBackendUser(account: UserAccount): any {
    const obj = {
      id: account.id,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      groups: account.groups.map((group) => group.name),
      phone: account.phone,
      langKey: account.langKey,
      addOns: {
        workflowDefaultLang: account.addOns.workflowDefaultLang,
        nickName: account.addOns.nickName,
        visualSign: account.addOns.visualSign,
        langNotifKey: account.addOns.langNotifKey,
        timeZone: account.addOns.timeZone ? account.addOns.timeZone : momentTZ.tz.guess(),
        emailSettingsRecipient: account.addOns.emailSettingsRecipient,
        emailSettingsStarter: account.addOns.emailSettingsStarter,
        tags: account.addOns.tags,
      },
    };

    if (account.activated && account.activated === true) {
      obj['login'] = account.identifiant !== undefined ? account.identifiant : account.login;
      obj['activated'] = account.activated;
    }

    return obj;
  }

  mapper(fullUser: FullUserObject): UserListItemDto {
    const id = fullUser.id;

    const firstName = fullUser.firstName;
    const lastName = fullUser.lastName;

    const email = fullUser.email;

    let keywords;

    if (fullUser.addOns !== undefined && fullUser.addOns !== null) {
      keywords = this.tagsToArray(fullUser.addOns.tags);
    }

    let rights;
    const listRights = new Set();

    fullUser.groups.forEach((group) => {
      const groupName = group.name;

      if (groupName.startsWith('admin')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.ADMIN').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('resp_metier')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.RESPMETIER').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('gest_circuit_junior')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.GESTCIRREST').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('gest_circuit')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.GESTCIR').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('gest_modele')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.GESTMODEL').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('resp_suivi')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.RESPSUIVI').subscribe((data) => listRights.add(data));
      } else if (groupName.startsWith('destinataire')) {
        this.translateService.get('ADMIN.USER.LIST.RIGHTS.RECIP').subscribe((data) => listRights.add(data));
      }

      rights = Array.from(listRights);
    });

    const tel = fullUser.phone;
    const activated = fullUser.activated;
    const disabled = fullUser.disabled;

    return new UserListItemDto(id, firstName, lastName, email, keywords, rights, tel, activated, disabled);
  }

  tagsToArray(tags) {
    if (typeof tags === 'string' || tags instanceof String) {
      const appRegExp = /"/gi;
      const accRegExp = /\[/gi;
      const slashRegExp = /\\/gi;
      const acc2RegExp = /]/gi;
      const spaceReg = /\s/g;

      tags = tags
        .replace(appRegExp, '')
        .replace(accRegExp, '')
        .replace(slashRegExp, '')
        .replace(acc2RegExp, '')
        .replace(spaceReg, '');
      if (tags.length > 0) return tags.split(',');
      else return [];
    } else return tags;
  }
}
