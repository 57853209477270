import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityRefService } from '@app/@services/entity-ref.service';
import { TokenService } from '@app/@services/authentification/token.service';
const headersConfig = {
  Accept: 'application/json',
  'x-pes-mode': 'prod',
  'x-pes-entity-ref': 'SELV2',
};

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  jwtService = inject(TokenService);

  entityService = inject(EntityRefService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.jwtService.getToken();
    const excludeUrls = ['internal/entities/front/', 'internal/authenticate/jwt/'];
    const jwtUrl = ['internal/authenticate/jwt/'];

    if (token && !excludeUrls.some((e) => req.url.includes(e))) {
      headersConfig['Authorization'] = `Bearer ${token}`;
      headersConfig['x-pes-entity-ref'] = this.entityService.entityRef;
      req = req.clone({ setHeaders: headersConfig });
    } else if (!token && jwtUrl.some((e) => req.url.includes(e))) {
      const entityRef = req.url.split('jwt/')[1].split('-')[0].toUpperCase();

      if (entityRef !== undefined) {
        headersConfig['x-pes-entity-ref'] = entityRef;
      }

      req = req.clone({ setHeaders: headersConfig });
    }

    return next.handle(req);
  }
}
